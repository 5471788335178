import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

const useGetUserAndOrganization = (firestore, auth) => {
  const [user, setUser] = useState({
    userDocumentId: "",
    userData: null,
  });

  const [organization, setOrganization] = useState({
    organizationId: "",
    organizationData: null,
  });

  const [loading, setLoading] = useState(false);

  //Query for user-document
  const collectionRef = firestore.collection('users');
  const userQuery = collectionRef.where('userID', '==', auth.currentUser.uid).limit(1);
  const [value, error] = useCollection(userQuery);

  const queryForOrganization = async (userData) => {
    const organizationQuery = firestore.collection('organizations').doc(userData.organization);
    const organizationDoc = await organizationQuery.get();

    if (organizationDoc.exists) {
      setOrganization({
        organizationId: organizationDoc.id,
        organizationData: organizationDoc.data(),
      })
    }
  }

  useEffect(() => {
    setLoading(true)
    let userData = null

    if (value && value.length > 0) {
      setUser({
        userDocumentId: value[0].id,
        userData: value[0].data()
      });

      userData = value[0].data();
    }

    //Conditional query for organization
    if (userData?.organization !== null && userData?.organization !== undefined && userData?.organization !== "") {
      try {
        queryForOrganization(userData);
      }
      catch (error) {
        console.log(error.message)
      }

    }

    setLoading(false)
  }, [value])

  return { user: user, organization: organization, loading: loading };
}

export default useGetUserAndOrganization;